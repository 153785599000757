.profile-section{
    padding: 20px;
    padding-bottom: 120px!important;

    .profile-card{
        border-radius: 30px;
    }
    .profile-photo-hover-icon{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
    }
}