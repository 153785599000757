/* Giriş-çıkış animasyonu (slide) */
.slide-enter {
  opacity: 0;
  transform: translateX(50%);
}
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-out;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: translateX(50%);
  transition: all 300ms ease-out;
}

/* Popover genel container */
.custom-notif-popover {
  width: 340px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
}

/* Üst başlık alanı */
.notif-header {
  background: #fff;
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notif-header-title {
  font-size: 16px;
  color: #000;
}

/* Bildirim listesi kabı */
.notif-list-container {
  max-height: 280px;
  overflow-y: auto;
}

/* Tek bir bildirim item'ı */
.notif-item {
  padding: 8px 12px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #f8f8f8;

}

/* Üst satır: Type + (date + Okundu icon) */
.notif-item-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px;
  margin-bottom: 6px;
  border-radius: 4px;
}

/* Alt kısım: "Tüm Duyurularım" */
.notif-footer {
  text-align: center;
  padding: 12px 0;
  font-weight: 500;
  cursor: pointer;
  border-top: 1px solid #f0f0f0;
  color: #4d73ff;
}
.notif-footer:hover {
  text-decoration: underline;
}