.vallet-card {
    position: relative;
    text-align: left;
    border-radius: 30px;
    height: 220px;

}

.vallet-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 190px;
}

.vallet-card-bg-dark {
    background-color: #000;
    color: #fff;
}

.vallet .card-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: #f0f0f0;
    display: flex;
    align-self: center;
    justify-self: center;
    border-radius: 50%;
    padding: 10px
}