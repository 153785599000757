.company-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}

.company-image-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.company-avatar {
  background-color: #f0f0f0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #000;
  margin-right: 10px;
}

.companies-action {
  display: flex;
  align-items: center;
  justify-content: end;
}



@media (max-width: 768px) {

  .company-image {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
  }

  .ant-avatar {
    width: 50px !important;
    height: 50px !important;
  }

  .company-avatar {
    width: 50px !important;
    height: 50px !important;
    font-size: 24px;
  }

  .company-image,
  .company-avatar {
    margin: 0;
  }

  .fs-24px {
    text-align: center;
  }

  .last-offer-container {
    text-align: center;
  }

  .company-card {
    .p-20px {
      padding: 0px;
    }
  }

}