/* Step Dairelerinin İçindeki Rakamları Gizle */
.custom-steps .ant-steps-icon {
    display: none;
    /* Rakamları gizler */
}

/* Step İkonları İçin Temel Stil */
.custom-steps .ant-steps-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #e0e0e0;
}

/* Tamamlanmış Step İçin Tik İşareti */
.custom-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #4e73df;
    color: #4e73df;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-steps .ant-steps-item-finish .ant-steps-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4e73df;
    border-radius: 50%;
    color: #fff !important;
    border: none;
    width: 24px;
    height: 24px;
    position: relative;
}

.custom-steps .ant-steps-item-finish .ant-steps-icon svg {
    display: none;
}

.custom-steps .ant-steps-item-finish .ant-steps-icon::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;
    border: solid white;
    border-width: 0px 0px 2px 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(315deg);
}

/* Aktif Olan Step İçin Mavi Nokta */
.custom-steps .ant-steps-item-process .ant-steps-item-icon {
    border-color: #4e73df;
    background-color: #fff;
    position: relative;
}

.custom-steps .ant-steps-item-process .ant-steps-item-icon::after {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #4e73df;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Bekleyen Step İçin Boş Daire */
.custom-steps .ant-steps-item-wait .ant-steps-item-icon {
    border-color: #e0e0e0;
    background-color: #fff;
}

/* Step Tail Çizgisi */
.custom-steps .ant-steps-item-tail {
    border-color: #e0e0e0;
}

.custom-steps .ant-steps-item-process .ant-steps-item-tail,
.custom-steps .ant-steps-item-finish .ant-steps-item-tail {
    border-color: #4e73df;
}

/* Step Başlıkları */
.custom-steps .ant-steps-item-title {
    margin-top: 8px;
    font-weight: 600;
    color: #000;
    text-align: start;
}

/* Step Açıklamaları */
.custom-steps .ant-steps-item-description {
    color: #6c757d;
    text-align: start;
    white-space: pre-wrap;
    /* Alt satıra geçiş için */
}

/* Tamamlanmış Adımlar İçin Açıklama Rengi */
.custom-steps .ant-steps-item-finish .ant-steps-item-description {
    color: #22AD5C !important;
}

/* Bekleyen Adımlar İçin Açıklama Rengi */
.custom-steps .ant-steps-item-wait .ant-steps-item-description {
    color: #6c757d;
}

.ant-steps-item-custom .ant-steps-item-icon {
    width: fit-content !important;
}

.ant-form-item-label {
    margin-bottom: 8px !important;
}

.ant-form-item {
    margin-bottom: 24px !important;
}

.last-offer-container {
    border: 1px solid #e0e0e0;
    padding: 2px 20px;
    border-radius: 24px;
    text-align: center;
}

.company-card {
    .fs-24px {
        font-size: 16px!important;
    }

  
    .last-offer-container{
        .ant-typography {
            font-size: 12px;
        }
    }

}