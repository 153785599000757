.auth-layout {

 

    .content-column {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    .form-header-title {
        font-weight: 400;
    }

    .auth-button {
        width: 100%;
    }

    .social-login {
        text-align: center;
        width: 100%;

        .google-login,
        .facebook-login {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .forgot-password {
        color: #6A0DAD;
        text-decoration: underline;
    }

    .fw-400 {
        font-weight: 400;
    }


    .mb-4 {
        margin-bottom: 4em;
    }

    .mt-4 {
        margin-top: 4em;
    }

    .mt-16px {
        margin-top: 24px;
    }

    .mb-8px {
        margin-bottom: 8px;
    }

    .sign-images {
        height: 30px;
        width: 30px;
    }

    .sign-buttons {
        width: 100%;
        font-weight: 600;
    }

    .text-center {
        text-align: center;
    }

    .card-image {
        align-items: center;
        object-fit: cover;
    }

    .ant-input {
        background-color: #fff;
        border: 0;

        &:hover {
            background-color: #fff !important;
        }

        &:focus {
            background-color: #fff !important;
        }

        &:active {
            background-color: #fff !important;
        }
    }

    .ant-input-group-addon {
        background-color: #fff;

        &:hover {
            background-color: #fff;
        }

        &:focus {
            background-color: #fff;
        }

        &:active {
            background-color: #fff;
        }
    }
}

.ant-form-item-explain-error {
    white-space: pre-wrap;
}
