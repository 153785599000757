.carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-track {
  display: flex;
  animation: scroll 12s linear infinite;
}

.carousel-item {
  flex: 0 0 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3%; 
}

.carousel-image {
  width: 100%;
  display: block;
}

.carousel::before,
.carousel::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  z-index: 1;
}

.carousel::before {
  left: 0;
  background: linear-gradient(to right, #435be7, rgba(67, 91, 231, 0));
}

.carousel::after {
  right: 0;
  background: linear-gradient(to left, #435be7, rgba(67, 91, 231, 0));
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-288%); 
  }
}