body {
  font-family: 'Urbanist', sans-serif;
  background: #F8F9FB;
  letter-spacing: 0.5px;
}


.bg-gray {
  background-color: #F6F7FB;
}

.bg-gray-2 {
  background-color: #f1f2f6;
}

.d-button {
  border-radius: 24px;
  height: 48px;
  font-size: 14px;
  width: 100%;
  padding: 20px !important;
  box-shadow: none !important;
}

.action-button {
  border-radius: 30px;
  height: 40px;
  font-size: 14px;
  padding: 20px !important;
  width: auto;
  color: #333E48;
  border: none;
  background-color: #f0f0f0;
  box-shadow: none !important;

  :hover {
    color: #333E48 !important;
  }

}

.action-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #333E48 !important;
}

.d-button-with-input {
  border-radius: 0 8px 8px 0 !important;
  height: 48px;
  font-size: 14px;
  background-color: #1B1E27;
  box-shadow: none !important;
}

.dark-button {
  background-color: #333E48;

  &:hover {
    background-color: #777C88 !important;
  }
}

input {
  box-shadow: none !important
}

.ant-input-affix-wrapper {
  box-shadow: none !important
}

.d-input-filter {
  border-radius: 30px !important;
  box-shadow: none !important;

  .ant-input {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.layout-content {
  .ant-pagination-item {
    border-color: #333E48 !important;

    a {
      color: #333E48 !important;
    }

    color: #1B1E27;


    .ant-select-single {
      height: 35px !important;
    }
  }
}

.d-input-form {
  border: 0!important;
    &:hover,
  &:focus,&:active,&:focus-within,&:visited,&:focus-visible {
  border: 0!important;
  box-shadow: none !important;

  }
}
.ql-editor {
  min-height: 200px; /* veya 300px */
  white-space: pre-wrap;

}

.content-html {
  white-space: pre-wrap;
}

// .d-input {
//   height: 48px;
//   font-size: 16px !important;
//   letter-spacing: 0.5px;
// }

// .d-input-form {
//   border-radius: 8px;
//   border: 0;
//   background-color: #F6F7FB !important;

//   &:hover,
//   &:focus {
//     background-color: #F6F7FB !important;
//   }

//   .ant-select-selector {
//     border: 0 !important;
//     background-color: transparent !important;
//     font-size: 16px;
//   }
// }

input {
  height: 48px;
  font-size: 16px !important;
  letter-spacing: 0.5px;

}

.ant-input-affix-wrapper {
  display: flex;
  height: 48px;
  align-items: center;
}

.layout-content {

  // input,
  // .ant-picker,
  // .ant-select,
  // .ant-input-search-button,
  // .ant-input-affix-wrapper,
  // .ant-input-group,
  // .ant-input-search-button {
  //   display: flex;
  //   align-items: center;
  // }
}

.layout-content {
  .ant-btn-primary {
    border-radius: 24px;
    height: 48px;
    font-size: 14px;
    width: 100%;
    padding: 20px !important;
    box-shadow: none !important
  }
}

.layout-content,
.ant-drawer,
.ant-modal-content {

  input,
  .ant-picker,
  .ant-select,
  .ant-input-search-button,
  .ant-input-affix-wrapper,
  .ant-input-group,
  .ant-input-search-button {
    height: 48px;
    font-size: 16px !important;
    letter-spacing: 0.5px;
    border-radius: 8px;
    border: 0;
    background-color: #F6F7FB !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      box-shadow: none !important;
      background-color: #F6F7FB !important;
    }
.ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    
  }
    .ant-select-selector {
      border: 0 !important;
      background-color: transparent !important;
      font-size: 16px;
      box-shadow: none !important;

      input {
        box-shadow: none !important;
        background-color: transparent !important;
      }
    }

    .ant-select-arrow {
      font-size: 18px;
      margin-right: 8px;
    }

  }

  .ant-input-number-input-wrap {
    width: 100%;
  }

  textarea {
    width: 100%;
    font-size: 16px;
    border-radius: 8px;
    border: 0;
    background-color: #F6F7FB !important;

    &:hover,
    &:focus {
      border: 0 !important;
      box-shadow: none !important;
      background-color: #F6F7FB !important;
    }

    .ant-select-selector {
      border: 0 !important;
      background-color: transparent !important;
      font-size: 16px;
    }
  }
}


.ant-form-item-label {
  padding: 0 !important;
}

.ant-form-item {
  margin-bottom: 16px;
}

.custom-tabs {
  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-tab {
    margin-left: 8px !important;
  }

  .tab-button {
    font-weight: 500;
  }

  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
}

.ant-popconfirm-buttons .ant-btn {
  font-weight: 500;
}

.lowercase {
  text-transform: lowercase !important;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.width-auto {
  width: auto !important;
}

.width-100px {
  width: 100px;
}

.width-100 {
  width: 100%;
}

.height-100px {
  height: 100px;
}

.height-100vh {
  height: 100vh;
}

.objectFit-cover {
  object-fit: cover;
}

.border-none {
  border: 0;
}

.border-bottom-1px {
  border-bottom: 1px solid #D9D9D9;
}

.m-0 {
  margin: 0px;
}


.borderRadius-50 {
  border-radius: 50%;
}

.br-15px {
  border-radius: 15px;
}

.br-16px {
  border-radius: 16px;
}

.br-30px {
  border-radius: 30px;
}

.bg-none {
  background: none;
}

.mt-0 {
  margin-top: 0px;
}

.mt-8px {
  margin-top: 8px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-16px {
  margin-top: 16px;
}

.mt-24px {
  margin-top: 24px;
}

.mt-30px {
  margin-top: 30px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-8px {
  margin-bottom: 8px;
}

.mb-16px {
  margin-bottom: 16px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-30px {
  margin-bottom: 30px;
}

.mb-60px {
  margin-bottom: 60px;
}

.mb-120px {
  margin-bottom: 120px;
}

.ml-8px {
  margin-left: 8px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-12px {
  margin-left: 12px;
}

.mr-10px {
  margin-right: 10px;
}

.mr-16px {
  margin-right: 16px;
}

.ml-16px {
  margin-left: 16px;
}

.mb-10px {
  margin-bottom: 10px;
}

.p-20px {
  padding: 20px;
}

.py-20px {
  padding: 20px 0;
}

.p-30px {
  padding: 30px;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start !important;
}

.fs-24px {
  font-size: 24px;
}

.fs-16px {
  font-size: 16px;
}

.fs-14px {
  font-size: 14px !important;
}

.weight-500 {
  font-weight: 500;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon-button {
  background-color: #f0f0f0;
  border: none;
  font-size: 18px;
  height: 40px !important;
  width: 40px !important;
}

.gap-16px {
  gap: 16px;
}

.ant-input-group-addon {
  border: 0 !important;
}

.ant-input-outlined {
  border: 0 !important;
}

.proposal-tabs {
  .table-input {
    border: 1px solid #D9D9D9 !important;
    border-radius: 4px !important;
    width: 90px !important;
  }
}

.hide-on-mobile {
  display: none;
}


@media (min-width: 576px) {
  .hide-on-mobile {
    display: inline;
  }
}



@media (max-width: 576px) {
  .ant-input-affix-wrapper {
    margin-bottom: 16px;
  }

  .d-button {
    border-radius: 24px;
    height: 48px;
    font-size: 12px;
    width: 100%;
  }
}


//modules
.ticket-detail-container {
  .ant-row button {
    border-radius: 24px;
    height: 48px;
    font-size: 12px;
    width: auto;
    padding: 20px !important;
  }

  input {
    height: 48px;
    width: 100%;
    font-size: 16px;
    border-radius: 8px;
    border: 0;
    background-color: #F6F7FB !important;

    &:hover,
    &:focus {
      background-color: #F6F7FB !important;
    }

    .ant-select-selector {
      border: 0 !important;
      background-color: transparent !important;
      font-size: 16px;
    }
  }
}

.ticket-container {
  button {
    border-radius: 24px;
    height: 48px;
    font-size: 12px;
    width: auto;
    padding: 20px !important;
  }


}

.avatar-image-logo {
  width: 40px !important;
  height: 40px !important;
}

.ant-table-row-selected {
  .ant-table-cell {
    background-color: #fafafa !important;
  }
}