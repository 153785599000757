/* Mobil menü düzenlemeleri */
 .menu-link {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    text-decoration: none;
  
    .menu-item-wrapper {
      display: flex;
      align-items: center;
  
      .menu-item {
        margin-bottom: 0;
        margin-right: 12px;
      }
  
      .menu-title {
        font-size: 16px;
        color: #000;
      }
    }
  }
  
  /* Genel menü düzenlemeleri */
  .menu-link {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    text-decoration: none;
  }
  
  .menu-item-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; /* Masaüstünde ikonlar merkezde olsun */
  }
  
  .mobile-menu .menu-item-wrapper {
    flex-direction: row; /* Mobilde ikon ve metin yan yana */
  }
  
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #ECEBF0;
  
    .icon {
      font-size: 24px;
      color: #818183;
    }
  }
  
  .menu-item.active {
    background-color: #333E48;
  
    .icon {
      color: #fff !important;
    }
  }
  
  .menu-item:hover {
    background-color: #333E48;
    border-radius: 50%;
  
    .icon {
      color: #fff;
    }
  }
// Sadece mobil menü için özel stil
.mobile-menu .menu-link {
    margin-bottom: 8px; // Mobilde daha az boşluk
}

.ant-layout-sider {
    .ant-layout-sider-children {
        display: flex;
        flex-direction: column !important;
        justify-content: space-between !important;
    }
}

.auth-layout {
    display: flex;
    flex-wrap: nowrap;
}

.content-column {
    overflow-y: auto;
}

.content-container {
    padding: 24px;
    width: 100%;
}

.sticky-card {
    position: sticky;
    top: 40px;
    margin: 40px;
    min-height: calc(100vh - 80px);
    // height: calc(100vh - 80px);
    border-radius: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: none;
    background-color: #435BE7;
    display: flex;
    flex-direction: column;
}

.card-title,
.card-text {
    color: #fff !important;
}

.card-title {
    margin-bottom: 16px;
}

.card-text {
    margin-bottom: 24px;
    display: block;
}

.card-image {
    margin-bottom: 24px;
    border-radius: 8px;
    width: auto;
    max-height: 360px !important;
    height: calc(100vh - 350px) !important;
}

.notify-box {
    width: 48px!important;
    height: 48px!important;
    border-radius: 50%;
    background-color: #fff;
}

.layout-sider {
    background: #F6F7FB !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;

    .menu-item-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.layout-content {
    padding: 24px 24px 100px 0;
    overflow: hidden;
    background-color: #F6F7FB;

    .layout-header {
        background: #F6F7FB;
    }
}

.p-16 {
    padding: 16px;
}

@media (max-width: 768px) {
    .layout-content {
        padding: 12px !important;

    }

    .ant-card-body {
        padding: 12px !important;
    }

    .hide-on-mobile {
        display: none;
    }

    .show-on-mobile {
        display: inline-block;
    }
}

@media (min-width: 769px) {
    .show-on-mobile {
        display: none;
    }
}