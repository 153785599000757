.antd-order-detail-collapse {

    background-color: #fff;
    border-radius: 30px;

    .ant-collapse-item {
        width: 100%;
    }

    &.bg-white {
        background: #fff;

        .ant-collapse-item-active {
            background: #fff;
        }
    }

    .ant-collapse-item-active {
        border: 1px solid #fff;
        border-radius: 1rem !important;
    }

    .ant-collapse-header,
    .ant-collapse-content-box,
    .ant-collapse-content {
        background: none !important;
    }

    .ant-collapse-content-box {
        border-radius: 1rem !important;
        padding: 0 1.5rem 1.5rem 1.5rem !important;

    }

    .ant-collapse-header {
        border-radius: 1rem !important;
        padding: 0 1.5rem 1rem   1.5rem !important;
        display:flex;
        align-items: center!important;

    }
}
.proposal-card{
    .ant-input-number-input-wrap .ant-input-number .ant-input-number-input{
        height: 48px!important;
    }
}