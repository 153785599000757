.dashboard-card {
    position: relative;
    text-align: left;
    border-radius: 30px;
    height: 175px;
    background-color: #FFFFFF; // Varsayılan renk
    padding: 20px;
    box-shadow: none !important;

    .ant-card-body {
        padding: 0 !important;
        height: 100%;
    }
}

.chart-card {
    border-radius: 30px;
    background-color: #fff;
}

.dashboard-card-bg-dark {
    background-color: #333E48;
    color: #fff;
}

.dashboard-card-bg-purple {
    background-color: #A8B5DF;
    color: #001433;
}

.dashboard-card-bg-gray {
    background-color: #E1E6EF;
    color: #001433;
}

.dashboard-card-bg-white {
    background-color: #FFFFFF;
    color: #001433;
}

.dashboard-card-bg-green {
    background-color: #DBF4ED;
    color: #001433;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}


.card-icon {
    background-color: #59626B;
    align-self: flex-start;
    /* İkonu sola yasla */
    border-radius: 50%;
    padding: 10px;
    height: 40px;
    width: 40px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    /* İkon ile metin arasında biraz boşluk bırak */
}

.card-value {
    padding: 0;
    margin: 0 !important;
    font-size: 36px;
}

.card-icon-arrow {
    height: 40px;
    font-size: 40px;
    font-weight: 300;
    width: 40px;

    span {
        font-size: 24px;
        height: 40px;
        font-weight: 300;
        width: 40px;
    }
}

.card-icon-dark {
    background-color: #333;
}

.card-info {
    font-weight: bold;
    font-size: 18px;
}

.card-value {
    font-size: 36px;
    margin-top: 10px;
    font-weight: 600;
}

.stats-card {
    background-color: #f7f8fb;
    padding: 20px;
    border-radius: 12px;
    height: 100px;
    display: flex;
    align-items: center;

    .ant-card-body {
        width: 100%;
        padding: 0;
    }
}

.stats-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.stats-text h4 {
    font-size: 14px;
    color: #888;
    margin-bottom: 4px;
}

.stats-value {
    font-size: 24px;
    font-weight: bold;
    color: #000;
}

.stats-icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #888;
}

.renuew-chart {
    height: 340px !important;

    .apexcharts-canvas {
        height: 180px !important;

        svg {
            height: 180px !important;
        }
    }
}